import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { catchError, of, tap } from 'rxjs';

import { LandingPage, LandingPages } from '@@core/models/landing-page.model';
import { LaunchDarklyService, LDFeatureKey } from '@@core/services/launch-darkly.service';
import { APP_CONFIG, initialApplicationConfig } from '@@shared/providers/application-config-provider/application-config-provider.model';

export const ConfigurationStore = signalStore(
	{ providedIn: 'root' },
	withState(() => ({ ...initialApplicationConfig, ...inject(APP_CONFIG) })),
	withComputed((store) => ({
		ssoMessage: computed(() => store.messages()?.sso),
		isCustomSupportMessageEnabled: computed(() => store.messages()?.support?.content && store.messages()?.support?.title),
		landingPagesSignal$: computed(() => {
			const customTabPages: Record<string, LandingPage> = {};
			store.customTabs()?.forEach((tab) => {
				const normalizedTabName = tab.name.split(' ').join('_').toLowerCase();
				const key = `custom_tab_${normalizedTabName}`;
				customTabPages[key] = {
					name: tab.name,
					value: key,
					route: '/custom-tab',
					queryParams: {
						url: tab.url,
						name: tab.name,
						type: 'iframe'
					}
				};
			});

			return {
				...LandingPages,
				...customTabPages
			};
		}),
	})),
	withMethods((store) => {
		const resetState = inject(APP_CONFIG);
		const launchDarklyService = inject(LaunchDarklyService);

		return {
			// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
			reset(): void {
				patchState(store, () => ({ ...initialApplicationConfig, ...resetState }));
			},

			// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
			loadCustomerConfig(): void {
				launchDarklyService.getJsonObjectForKey(LDFeatureKey.CUSTOMER_WEB_APP_CONFIG).pipe(
					tap((config) => patchState(store, (state) => ({ ...state, ...(config || {}) }))),
					catchError((err) => of({}))
				).subscribe();
			}
		};
	})
);
